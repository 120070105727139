<template>
    <section class="userSteps ContentBlock vh-100">
        <div class="container" v-if="step==1">
            <div class="row justify-content-center vh-100 align-items-center">
                <div class="col-12 col-md-8 col-xl-5">
                    <div class="bg-white boxed p-0 px-md-5 py-md-4">
                        <form class="inputForm">
                            <div class="text-center">
                                 <img class="mb-4" width="180" height="40" src="/assets/images/logo.svg" :alt="$t('head.text21')" :title="$t('header.zulu_trade')" />
                             </div>
                            <h3 class="text-center mb-md-5 mb-4">{{$t('registerSteps.registerText1')}}</h3>
                            <!-- <div class="d-flex justify-content-center googleSignInButton">
                                <GoogleSignInButton @success="handleLoginSuccess" @error="handleLoginError"
                                    size="medium"></GoogleSignInButton>
                            </div>
                            <div class="text-center">
                                <p class="or medium text-center my-4">OR</p>
                            </div> -->
                            <Form @submit="register">
                                <div class="d-flex align-items-start flex-wrap">
                                    <float-label class="form-group w-md-50 pe-md-2">
                                      <Field
                                        class="form-control"
                                        name="first name"
                                        @keydown = "onInputString($event,'fname')"
                                        :placeholder="$t('registerSteps.registerText2')"
                                        v-model="form.fname"
                                        rules="required"
                                      />
                                      <ErrorMessage class="text-danger" name="first name" />
                                  </float-label>
                                  <float-label class="form-group w-md-50 ps-md-2">
                                    <Field
                                      class="form-control"
                                      name="last name"
                                      @keydown = "onInputString($event,'lname')"
                                      :placeholder="$t('registerSteps.registerText3')"
                                      v-model="form.lname"
                                      rules="required"
                                    />
                                    <ErrorMessage class="text-danger" name="last name" />
                                    </float-label>
                                </div>
                                <div class="countrySelect form-group position-relative filter-dropdown"  :class="[{'selected' : country.name}]">
                                    <a @click="selectButton = !selectButton" href="javascript:void(0)"
                                        class="selectButton form-control d-flex align-items-center justify-content-between"><span
                                            class="d-flex align-items-center" v-if="country.id && country.name"><img class="me-2" :src="'/assets/images/country_flag/'+country.name.toLowerCase()+'.webp'"
                                                alt="Icon" title="Icon" loading="lazy" width="20" height="20"/>{{country.name}}</span>
                                                <span v-else>{{$t('registerSteps.registerText4')}}</span>
                                        <vue-feather size="20" type="chevron-down"></vue-feather>
                                    </a>
                                    <label class="afterSelect">{{$t('registerSteps.registerText4')}}</label>
                                    <ul class="dropdown_menu_animated scrollable" :class="selectButton ? 'show' : ''" > 
                                        <li class="form-group position-sticky mb-1">
                                            <input type="text" :placeholder="$t('registerSteps.registerText5')" class="form-control radius-0" v-model="countrysearch" />
                                        </li>
                                        <li v-for="item,key in getCountries" :key="key">
                                            <a href="javascript:void(0)" class="d-flex align-items-center" @click="country = item;selectButton = false;countrysearch = '';changecountry(item)" ><img class="me-2"
                                                    :src="'/assets/images/country_flag/'+item.name.toLowerCase()+'.webp'" alt="Icon" :title="item.name" loading="lazy" width="20" height="20"/>
                                                {{item.name}}</a>
                                        </li>
                                    </ul>
                                    <span v-if="isCountry" role="alert" class="text-danger">{{$t('changes.text6')}}</span>
                                  </div>
                                  <div class="form-group mb-4 position-relative" :class="selectNumber ? 'selected' : ''">
                                    <Field
                                        v-slot="{ field }"
                                        name="Phone Number"
                                        v-model="form.mobile" >
                                        <vue-tel-input
                                        v-bind="field"
                                        :custom-validate="form.mobile!=''? new RegExp(/^[0-9]+$/):false"
                                        v-model="form.mobile"
                                        @country-changed="GetCode"
                                        :inputOptions="options"
                                        :validCharactersOnly = "true"
                                        :onlyCountries ="getAllowCountry()"
                                        ref="phone"
                                        ></vue-tel-input>
                                  </Field>
                                  <span v-if="form.mobile == ''" class="text-danger" style="font-size: 14px;">phone number is required</span>
                                  <span v-if="isPhone &&  form.mobile != ''" role="alert" class="text-danger">{{$t('changes.text45')}}</span>
                                </div>
                                <float-label class="form-group">
                                    <!-- <input type="text" class="form-control" placeholder="Username or email" /> -->
                                    <Field class="form-control" type="email" name="Email Address" autofill="off" autocomplete="username"
                                        v-model="form.username" placeholder="Email Address" rules="required|email" />
                                    <ErrorMessage class="text-danger" name="Email Address" />
                                </float-label>
                                <float-label class="form-group mb-1">
                                    <div class="position-relative iconInput">
                                        <!-- <input type="password" class="form-control" placeholder="Enter Password" /> -->
                                        <Field :type="inputtype" class="form-control" autofill="off" name="Password"
                                            v-model="form.password" rules="required|min:8" placeholder="Enter Password"
                                            autocomplete="new-password" />
                                        <ErrorMessage class="text-danger" name="Password" />
                                        <vue-feather class="position-absolute eye pointer" :type="iconType" size="23"
                                            @click="switchVisibility"></vue-feather>
                                    </div>
                                    <div class="toolMob d-flex align-items-center justify-content-end"
                                        :class="(!validPassword && form.password) ? 'orange' : (validPassword && form.password) ? 'green' : ''">
                                        <p class="f-10 mb-0">{{$t('registerSteps.registerText6')}}</p>
                                        <div class="tooltipbutton w-auto">
                                            <span class="tooltiptext">
                                                <div class="strenghtPassword m-0">
                                                    <ul class="passwordStrenth mb-0">
                                                        <li :class="(upperCaseOk) ? 'green' : 'red'">{{$t('registerSteps.registerText7')}}</li>
                                                        <li :class="(form.password.length >= 8) ? 'green' : 'red'">{{$t('registerSteps.registerText8')}}</li>
                                                        <li :class="(numberOk) ? 'green' : 'red'">{{$t('registerSteps.registerText9')}}</li>
                                                        <li :class="(letterOk) ? 'green' : 'red'">{{$t('registerSteps.registerText10')}}</li>
                                                        <li :class="(SpecialCharOk) ? 'green' : 'red'">{{$t('registerSteps.registerText11')}}</li>
                                                    </ul>
                                                </div>
                                            </span>
                                            <vue-feather class="mx-1 alertType"
                                                :type="(!validPassword && form.password) ? 'alert-circle' : (validPassword && form.password) ? 'check-circle' : 'alert-circle'" size="18"></vue-feather>
                                        </div>
                                        <span class="barPass"></span>
                                    </div>
                                </float-label>
                                <div class="buttonElement my-3 form-group">
                                    <button type="submit" class="button fillBtn large w-100"
                                        :class="(!validPassword || !captchaToken)?'disabled':''">{{$t('registerSteps.registerText12')}} </button>
                                </div>
                            </Form>
                            <!-- <div class="text-center form-group">
                                <p class="mb-0">Already have an account? <a :href="`${static_vars.domainURL}login`"
                                        class="underline">login </a> </p>
                            </div> -->
                        </form>
                    </div>
                </div>
            </div>
        </div>
        <!-- <OTP v-else /> -->
        <!-- v-else-if="step=2" -->
        <div class="container" v-if="step == 2">
            <div class="row justify-content-center vh-100 align-items-center">
                <div class="col-12 col-md-8 col-xl-5">
                    <div class="text-center">
                         <img class="mb-4" width="180" height="40" src="/assets/images/logo.svg" :alt="$t('head.text21')" :title="$t('header.zulu_trade')" />
                     </div>
                    <div class="pt-0 text-center genrateMessage mb-4 pb-1">
                        <img height="100" width="100" class="d-block mx-auto mb-4" src="/assets/images/success14789.png" :alt="$t('registerSteps.registerText13')" />
                        <h4 class="my-3">{{$t('registerSteps.registerText14')}} </h4>
                    </div>
                    <div class="w-100">
                        <a class="button fillBtn large w-100" href="javascript:void(0)" @click="goToWebsite()">{{$t('registerSteps.registerText15')}}</a>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
    import { myStore } from "@/store/pinia-store";
    import { Form, Field, ErrorMessage } from "vee-validate";
    import FloatLabel from 'vue-float-label/components/FloatLabel'
    import PriceFeed from '@/store/stomp';
    import { load } from 'recaptcha-v3'
    // import OTP from '@/views/auth/get_otp.vue'
    import $ from 'jquery'
    export default {
        setup() {
            const store = myStore();
            const Crypto = require("crypto-js");
            return { store,Crypto}; //Crypto
        },
        data() {
            return {
                form: {
                    username: '',
                    password: '',
                    fname : '',
                    lname : '',
                    mobile : '',
                    phonecode : ''
                },
                selectNumber : false,
                country : {},
                captchaToken: '',
                showpassword: false,
                iconType: "eye-off",
                inputtype: "password",
                upperCaseOk: false,
                numberOk: false,
                SpecialCharOk: false,
                letterOk: '',
                validPassword: false,
                step: 1,
                loader : false,
                options: { placeholder: "Enter your phone number" },
                isCountry : false,
                selectButton: false,
                countrysearch : '',
                isPhone : false,
            }
        },
        watch: {
            "form.password": function (val) {
                let upperCase = new RegExp("[A-Z]"),
                    numbers = new RegExp("[0-9]"),
                    letter = new RegExp("[A-Za-z]"),
                    specialcharacter = new RegExp("[!,%,&,@,#,$,^,*,?,_,~,/]");
                if (val.match(upperCase)) {
                    this.upperCaseOk = true
                } else {
                    this.upperCaseOk = false
                }
                if (val.match(numbers)) {
                    this.numberOk = true
                } else {
                    this.numberOk = false
                }
                if (val.match(letter)) {
                    this.letterOk = true;
                } else {
                    this.letterOk = false;
                }
                if (val.match(specialcharacter)) {
                    this.SpecialCharOk = true;
                } else {
                    this.SpecialCharOk = false;
                }
                if (this.upperCaseOk && this.numberOk && this.SpecialCharOk && val.length >= 8 && this.letterOk) {
                    this.validPassword = true
                } else {
                    this.validPassword = false
                }

            },
            "store.flavorData"(){
                if(Object.keys(this.store.countriesList).length && this.store.flavordata?.userCountry?.id){
                    if(Object.keys(this.store.countriesList).includes(this.store.flavordata?.userCountry?.id.toString())){
                        this.country = this.store.countriesList[this.store.flavordata?.userCountry?.id]
                        this.changecountry(this.country)
                    }else{
                        this.country = Object.values(this.store.countriesList)[0]
                        this.changecountry(this.country)
                    }
                }
            },
            "form.mobile"(){
                if(this.form.mobile && this.form.mobile.length >= 7){
                    this.isPhone = false
                }
            },
            "store.countriesList"(){
                if(Object.keys(this.store.countriesList).length && this.store.flavordata?.userCountry?.id){
                    if(Object.keys(this.store.countriesList).includes(this.store.flavordata?.userCountry?.id.toString())){
                        this.country = this.store.countriesList[this.store.flavordata?.userCountry?.id]
                        this.changecountry(this.country)
                    }else{
                        this.country = Object.values(this.store.countriesList)[0]
                        this.changecountry(this.country)
                    }
                }
            }
        },
        computed:  {
            getCountries(){
                if(Object.keys(this.store.countriesList).length > 0){
                    let data = Object.values(this.store.countriesList);
                    if(this.countrysearch){
                        return data.filter(item => item.name.toLowerCase().includes(this.countrysearch.toLowerCase()))
                    }else{
                        return data
                    }

                }else{
                    return []
                }
            }
        },
        components: {
            // OTP,
            FloatLabel,
            Form,
            Field,
            ErrorMessage,
        },
        methods: {
            // onInput(e) {
            // this.form.mobile = e.target.value;
            // },
            onInputString(e,field_name) {
                const keyCode = e.keyCode || e.which;
                if(!this.COMMON.validateIsString(keyCode)){
                    e.preventDefault();
                }
                else{
                    this.form[field_name] = e.target.value;      
                }
            },
            // onInput(e) {
            //     const keyCode = e.keyCode || e.which;
            //     if(!this.COMMON.validateIsNumber(keyCode)){
            //         e.preventDefault();
            //     }
            //     else{
            //         this.form.mobile = e.target.value;      
            //     }
            // },
            GetCode(e) {
            this.form.phonecode = e.dialCode;
            },
            changecountry(item) {
                this.$refs.phone.choose(item.isoCode);
            },
            CretioFunc(){
                try{
                    // window.gtag('event', 'email_verify');
                  if(Object.keys(this.store.flavordata).length && this.store.flavordata.userCountry && Object.keys(this.store.flavordata.userCountry).length && this.store.flavordata.userCountry.isoCode){
                        for(var key in this.static_vars.cretioCountryJson){
                            if(key == this.store.flavordata.userCountry.isoCode){
                                var s = document.createElement("script");
                                s.type = "text/javascript";
                                s.async = true;
                                s.src = `//dynamic.criteo.com/js/ld/ld.js?a=${this.static_vars.cretioCountryJson[key]}`;
                                $("head").append(s);
                                window.criteo_q = window.criteo_q || [];
                                 var deviceType = /iPad/.test(navigator.userAgent) ? "t" : /Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Silk/.test(navigator.userAgent) ? "m" : "d";
                                window .criteo_q.push(
                                 { event: "setaccount", account: this.static_vars.cretioCountryJson[key]}, // You should never update this line
                                 { event: "setSiteType", type: deviceType},
                                 { event: "setemail", email: this.Crypto.SHA256('1').toString() , hash_method: "sha256" },
                                { event: "viewBasket", user_segment : 1, item: [
                                   {id : (Math.random()*10000).toString(), price:1, quality : 1}
                                ]}
                                );
                            }
                        }
                    }
                }catch(e){
                  console.log('cretio ex',e)
                }
            },
            CretioGoogleFunc(){
                try{
                    window.gtag('event', 'Gen_Lead_2.0');
                    window.gtag("event", "test_verify");
                    window.fbq('trackCustom', 'email_verify', {'email' : this.store.customerDetail?.email,'value' : '20','country' : this.store.flavordata?.userCountry?.name},{eventID: `${this.store.customerDetail?.id}_email_verify`});
                    if(Object.keys(this.store.flavordata).length && this.store.flavordata.userCountry && Object.keys(this.store.flavordata.userCountry).length && this.store.flavordata.userCountry.isoCode){
                        for(var key in this.static_vars.cretioCountryJson){
                            if(key == this.store.flavordata.userCountry.isoCode){
                                var s = document.createElement("script");
                                s.type = "text/javascript";
                                s.async = true;
                                s.src = `//dynamic.criteo.com/js/ld/ld.js?a=${this.static_vars.cretioCountryJson[key]}`;
                                $("head").append(s);
                                window.criteo_q = window.criteo_q || [];
                                 var deviceType = /iPad/.test(navigator.userAgent) ? "t" : /Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Silk/.test(navigator.userAgent) ? "m" : "d";
                                window .criteo_q.push(
                                 { event: "setaccount", account: this.static_vars.cretioCountryJson[key]}, // You should never update this line
                                 { event: "setSiteType", type: deviceType},
                                 { event: "setemail", email: this.Crypto.SHA256('1').toString() , hash_method: "sha256" }, 
                                  //{ event: "setEmail", email: "1"  },
                                    { event: "viewBasket", user_segment : 1, item: [
                                         {id : (Math.random()*10000).toString(), price:1, quality : 1}
                                    ]},
                                    { event: "trackTransaction", id: (Math.random() * 50000), item: [
                                        {id :  Math.ceil(Math.random()*10300 * Math.random() * 50000), price:1, quality : 1}
                                    ]}
                                );
                            }
                        }
                    }
                }catch(e){
                  console.log('cretio ex',e)
                }
            },
            getAllowCountry(){
                return Object.values(this.store.countriesList).map((val) => val.isoCode) || [];
            },
            register(type, response) {
                load(`${this.static_vars.recaptchaKey}`, { useRecaptchaNet: true }).then((recaptcha) => {
                recaptcha.execute('login').then((token) => {
                    // console.log(token) // Will print the token
                    this.captchaToken = token
                    if(this.captchaToken){
                        let formData = {};
                        if (this.validPassword) {
                            if (this.validEmail()) {
                                formData['email'] = this.form.username
                                formData['password'] = this.form.password
                                formData['tokenType'] = 'EMAIL'
                            } else {
                                formData['username'] = this.form.username
                                formData['password'] = this.form.password
                                formData['tokenType'] = 'USERNAME'
                            }
                            if (this.captchaToken) {
                                formData['captcha'] = {};
                                formData['captcha']['response'] = this.captchaToken
                            }
                            if(Object.keys(this.country).length){
                                this.isCountry = false
                            }else{
                                this.isCountry = true
                            }
                            if(this.form.mobile){
                              this.isPhone = false
                            }else{
                              this.isPhone = true
                            }
                            if(this.form.fname){
                              formData['firstname'] = this.form.fname
                            }
                            if(this.form.lname){
                              formData['lastname'] = this.form.lname
                            }
                            if(this.country?.id){
                              formData['countryId'] = this.country.id
                            }
                            if(this.form.mobile){
                              formData['phoneNumber'] = this.form.mobile
                            }
                            if(this.form.mobile){
                              formData['phoneCountryCode'] = this.form.phonecode
                            }
                            if(!this.isPhone && !this.isCountry){
                                this.store.register(formData, true, this, '');
                            }
                        } else {
                            if (type) {
                                formData['tokenType'] = 'GOOGLE'
                                formData['grant_type'] = 'password';
                                formData['token'] = response;
                                if (this.captchaToken) {
                                    formData['captcha'] = {};
                                    formData['captcha']['response'] = this.captchaToken
                                }
                                this.store.register(formData, true, this, type);
                            }
                        }
                    }
                })
            })
            },
            handleLoginSuccess(response) {
                if (response.credential) {
                    this.register('Google', response.credential)
                }
            },
            handleLoginError() {
                console.error("Login failed");
            },
            loginMannual() {
                let formData = {};
                formData['grant_type'] = 'password'
                formData['username'] = this.form.username
                formData['password'] = this.form.password
                // formData['tokenType'] = data
                this.store.login(formData, false, 'basic', this)
            },
            loginWithGoogle(data) {
                let formData = {};
                formData['grant_type'] = 'password'
                formData['googleToken'] = data
                this.store.login(formData, false, 'basic', this)
            },
            callCustomerDetail() {
                this.store.callCustomerDetail({}, true).then(resp =>{
                    if(Object.keys(resp).length){
                        this.step = 2
                        // this.$router.replace('/dashboard/personal-info')
                        /*if(Object.keys(resp).length && resp.email){
                            let path = `${this.static_vars.domainURL}register`;
                            let qs = new URLSearchParams(this.$route.query);
                            path += `?${qs.toString()}`;
                            window.open(path,'_self')
                        }else{
                            let path = `${this.static_vars.domainURL}user-details`;
                            let qs = new URLSearchParams(this.$route.query);
                            path += `?${qs.toString()}`;
                            window.open(path,'_self')
                        }*/
                    }
                })
            },
            goToWebsite(){
                if(Object.keys(this.store.customerDetail).length && this.store.customerDetail.email){
                    let path = `${this.static_vars.domainURL}register`;
                    let qs = new URLSearchParams(this.$route.query);
                    path += `?${qs.toString()}`;
                    window.open(path,'_blank')
                }else{
                    let path = `${this.static_vars.domainURL}user-details`;
                    let qs = new URLSearchParams(this.$route.query);
                    path += `?${qs.toString()}`;
                    window.open(path,'_blank')
                }
            },
            validEmail() {
                let regexp = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
                return regexp.test(this.form.username.toLowerCase())
            },
            switchVisibility() {
                this.showpassword = !this.showpassword;
                if (this.showpassword) {
                    this.inputtype = "text";
                    this.iconType = "eye";
                } else {
                    this.inputtype = "password";
                    this.iconType = "eye-off";
                }
            },
            callSocket(){
                PriceFeed.getInstance().deactivate();
            },
            getEmailCode(){
                let formData = {};
                formData['identifier'] = 'EMAIL'
                formData['email'] = this.store.customerDetail.email
                this.store.resendOTP(formData,true,this);
            },
            SetToken(response){
                if(Object.keys(response).length && response.access_token){
                    localStorage.setItem('zulutoken', response.access_token);
                    if(response.refresh_token){
                        localStorage.setItem('zulurefreshToken', response.refresh_token);
                    }
                }
            }
        },
        mounted() {
            load(`${this.static_vars.recaptchaKey}`, { useRecaptchaNet: true }).then((recaptcha) => {
                recaptcha.execute('login').then((token) => {
                    // console.log(token) // Will print the token
                    this.captchaToken = token
                })
            })
        },
        created(){
            console.log("register");

            this.store.getFlavorID({}, false)
            this.store.getCountryList({},true);
            this.CretioFunc()
        }
    }
</script>
<style>
    .disabled {
        pointer-events: none;
        opacity: 0.5;
    }
</style>